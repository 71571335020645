/** @jsxImportSource @emotion/react */
import useGlobalState, { initialState } from "../state";
import DraggableNumberInput from "./DraggableNumberInput";

export default function Dpi() {
  const [{ dpi, theme }, { setDpi }] = useGlobalState();

  const handleDpiBlur = (e) => {
    const { value } = e.target;
    if (value == 0) {
      setDpi(initialState.dpi);
    }
    setDpi(value);
  };

  const handleDpiChange = (e) => {
    setDpi(e.target.value);
  };

  return (
    <div
      css={{
        position: "absolute",
        top: theme.key === "valorant" ? "1.7rem" : "1.5rem",
        left: "2rem",
        color: theme.color,
      }}
    >
      <span
        css={{
          display: "inline-block",
          marginRight: "0.5em",
          fontFamily: theme.body,
          fontSize: "0.9rem",
        }}
      >
        DPI
      </span>
      <input
        value={dpi}
        onBlur={handleDpiBlur}
        onChange={handleDpiChange}
        min={100}
        step={50}
        max={10000}
        color={theme.color}
        css={{
          width: "6rem",
          fontFamily: theme.heading,
          fontSize: theme.key === "valorant" ? "2.2rem" : "2.9rem",
          padding: 0,
          textAlign: "left",
          color: "inherit",
          border: "none",
          background: "transparent",
          lineHeight: "1em",
          WebkitAppearance: "none",
          appearance: "none",
          ":focus": {
            border: "none",
            outline: "none",
          },
        }}
      />
    </div>
  );
}
