import { createStore, createHook } from "react-sweet-state";
import { toCsgoSens, toValorantSens } from "../util";
import themes from "./themes";

export const initialState = {
  sens: 1.75,
  valorantSens: 0.55,
  dpi: 400,
  theme: {
    key: "csgo",
    ...themes["csgo"],
  },
};

const Store = createStore({
  initialState,
  actions: {
    setTheme:
      (themeKey) =>
      ({ setState }) => {
        setState({ theme: themes[themeKey] });
      },

    setSens:
      (sens) =>
      ({ setState }) => {
        setState({ sens, valorantSens: toValorantSens(sens) });
      },

    setValorantSens:
      (valorantSens) =>
      ({ setState }) => {
        setState({
          valorantSens,
          sens: toCsgoSens(valorantSens),
        });
      },

    setDpi:
      (dpi) =>
      ({ setState }) =>
        setState({ dpi }),
  },

  name: "state",
});

const useGlobalState = createHook(Store);

export const useTheme = () => {
  const [values] = useGlobalState();
  return values.theme;
};

export default useGlobalState;
