export default function ValLogo({ fill = "white", size = 100 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4109 18.1755C11.889 17.8904 12.1937 18.4666 12.4516 18.7698C29.0469 39.5308 45.6617 60.2768 62.2555 81.0379C62.5964 81.3335 62.3385 81.9655 61.8784 81.8976C53.933 81.9066 45.986 81.9006 38.0406 81.9006C37.4041 81.9081 36.7947 81.5914 36.4041 81.0952C28.16 70.7953 19.9204 60.491 11.6763 50.1896C11.3264 49.7734 11.1454 49.2349 11.1681 48.6934C11.1681 38.742 11.1711 28.7891 11.165 18.8376C11.1696 18.6023 11.1605 18.2977 11.4109 18.1755Z"
        fill={fill}
      />
      <path
        d="M88.1627 18.1268C88.502 17.9986 88.8761 18.3123 88.8263 18.6682C88.8384 28.6242 88.8248 38.5802 88.8323 48.5361C88.855 49.1002 88.7313 49.6839 88.3648 50.1273C85.8596 53.2615 83.3514 56.3941 80.8462 59.5267C80.4284 60.0697 79.7451 60.3699 79.0619 60.3352C71.1692 60.3276 63.275 60.3397 55.3824 60.3291C54.9163 60.4015 54.6509 59.7726 54.9947 59.4694C65.9401 45.776 76.8945 32.0871 87.8459 18.3968C87.9289 18.2791 88.0345 18.1886 88.1627 18.1268Z"
        fill={fill}
      />
    </svg>
  );
}
