export default {
  csgo: {
    key: "csgo",
    heading: `"Teko", sans-serif`,
    body: `"Changa", sans-serif`,
    background:
      "radial-gradient(69.96% 69.96% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(23, 25, 33, 0.7) 100%), linear-gradient(242.94deg, #2C323C 0.54%, #5B6782 50.96%, #BC976D 100%), #FFFFFF",
    color: "white",
    selectionColor: "#4a5369",
  },
  valorant: {
    key: "valorant",
    heading: `"Anton", sans-serif`,
    body: `"Barlow", sans-serif`,
    background:
      "radial-gradient(98.18% 98.18% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.0697926) 21.69%, rgba(0, 0, 0, 0.0787841) 35.74%, rgba(0, 0, 0, 0.520125) 66.65%, #000000 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(117.74deg, #0B253C 2.81%, #701D27 95.43%), #FFFFFF",
    color: "#ff4655",
    selectionColor: "#ff4655",
  },
};
