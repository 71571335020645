/** @jsxImportSource @emotion/react */
import { useTheme } from "../state";
import { Global, css } from "@emotion/react";

export default function GlobalStyles() {
  const { selectionColor } = useTheme();

  return (
    <Global
      styles={css`
        ::selection {
          color: white;
          background: ${selectionColor};
        }
      `}
    />
  );
}
