/** @jsxImportSource @emotion/react */

export default function Button({ onClick, color, children, active }) {
  return (
    <button
      onClick={onClick}
      css={{
        color,
        padding: 0,
        outline: "none",
        opacity: active ? 1 : 0.5,
        cursor: "pointer",
        appearance: "none",
        background: "transparent",
        border: "none",
        transition: "transform 0.2s ease",
        ":hover": {
          transform: "scale(1.1)",
        },
      }}
    >
      {children}
    </button>
  );
}
