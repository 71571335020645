/** @jsxImportSource @emotion/react */
import { useTheme } from "../state";
import GlobalStyles from "./GlobalStyles";
import Dpi from "./Dpi";
import SelectGame from "./SelectGame";
import SensInput from "./SensInput";
import BottomStats from "./BottomStats";

export default function Root() {
  const theme = useTheme();
  return (
    <div
      css={{
        overflow: "hidden",
        color: theme.color,
        position: "absolute",
        inset: 0,
        background: theme.background,
      }}
    >
      <GlobalStyles />
      <Dpi />
      <SelectGame />
      <SensInput />
      <BottomStats />
    </div>
  );
}
