/** @jsxImportSource @emotion/react */

import useGlobalState, { initialState } from "../state";
import DraggableNumberInput from "./DraggableNumberInput";

export default function SensInput() {
  const [{ sens, valorantSens, theme }, { setSens, setValorantSens }] =
    useGlobalState();

  const currentSens = {
    value: theme.key === "csgo" ? sens : valorantSens,
    setValue: theme.key === "csgo" ? setSens : setValorantSens,
  };

  const handleSensChange = (newValue) => {
    currentSens.setValue(newValue);
  };

  return (
    <div
      css={{
        transform: "translate(-50%, -50%)",
        position: "absolute",
        top: "50%",
        left: "50%",
        textAlign: "center",
      }}
    >
      <DraggableNumberInput
        value={currentSens.value}
        // onBlur={handleSensBlur}
        onChange={handleSensChange}
        min={0}
        step={theme.key === "valorant" ? 0.01 : 0.05}
        max={50}
        // width="25rem"
        color={theme.color}
        fontFamily={theme.heading}
        fontSize={theme.key === "valorant" ? "10rem" : "14rem"}
        marginTop={theme.key === "valorant" ? "0" : "0.15em"}
      />
    </div>
  );
}
