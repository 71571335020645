/** @jsxImportSource @emotion/react */
import useGlobalState from "../../state";
import CsLogo from "./CsLogo";
import ValLogo from "./ValLogo";
import Button from "./Button";

export default function SelectGame() {
  const [{ theme }, { setTheme }] = useGlobalState();

  return (
    <div
      css={{
        top: "2em",
        width: "8em",
        display: "flex",
        justifyContent: "space-between",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        "@media (max-width: 600px)": {
          transform: "none",
          left: "auto",
          right: "2em",
        },
      }}
    >
      <Button
        active={theme.key === "csgo"}
        onClick={() => setTheme("csgo")}
        color={theme.color}
      >
        <CsLogo size="3rem" fill={theme.color} />
      </Button>
      <Button
        active={theme.key === "valorant"}
        onClick={() => setTheme("valorant")}
        color={theme.color}
      >
        <ValLogo size="3rem" fill={theme.color} />
      </Button>
    </div>
  );
}
