/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import { round } from "../util";
import { useTheme } from "../state";

export default function DraggableNumberInput({
  width,
  fontSize,
  fontFamily,
  min,
  max,
  step,
  onBlur,
  onChange,
  value,
  marginTop,
}) {
  const { key } = useTheme();
  const [snapshot, setSnapshot] = useState(value);
  const [isDragging, setIsDragging] = useState(false);
  const [initial, setInitial] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (!isDragging) {
      setSnapshot(value);
    }
  }, [value, isDragging]);

  useEffect(() => {
    const onEnd = () => {
      setSnapshot(value);
      setIsDragging(false);
    };

    const handleMouseMove = (e) => {
      if (isDragging) {
        const difference = -initial.x + e.clientX;
        const increment = 400;
        const newValue = round(snapshot + difference / increment, 2);
        onChange(newValue > 0 ? newValue : 0);
      }
    };

    const handleTouchMove = (e) => {
      if (isDragging) {
        const difference = initial.y - e.touches[0].clientY;
        const increment = 300;
        const newValue = round(snapshot + difference / increment, 2);
        onChange(newValue > 0 ? newValue : 0);
      }
    };

    const handleScroll = (e) => e.preventDefault();

    document.addEventListener("scroll", handleScroll);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", onEnd);
    document.addEventListener("mouseup", onEnd);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", onEnd);
      document.removeEventListener("mouseup", onEnd);
    };
  }, [isDragging, initial.x, initial.y]);

  const handleMouseDown = (e) => {
    setInitial({ x: e.clientX, y: e.clientY });
    setIsDragging(true);
    e.preventDefault();
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    const { clientX, clientY } = e.touches[0];
    setInitial({ x: clientX, y: clientY });
    setIsDragging(true);
  };

  return (
    <input
      min={min}
      max={max}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      value={value}
      type="text"
      step={step}
      css={{
        width,
        fontSize,
        fontFamily,
        marginTop,
        marginLeft: "1rem",
        padding: 0,
        textAlign: "center",
        color: "inherit",
        border: "none",
        background: "transparent",
        lineHeight: "1em",
        WebkitAppearance: "none",
        appearance: "none",
        ":focus": {
          border: "none",
          outline: "none",
        },
      }}
    />
  );
}
