/** @jsxImportSource @emotion/react */
import useGlobalState from "../state";
import { toEdpi, toValorantSens, toCm360 } from "../util";

export default function BottomStats() {
  const [{ theme, sens, dpi }] = useGlobalState();

  const eDpi = toEdpi(sens, dpi);
  const cm360 = toCm360(eDpi);
  const vDpi = toEdpi(toValorantSens(sens), dpi);

  return (
    <div
      css={{
        display: "flex",
        justifyContent: "center",
        fontWeight: 500,
        fontFamily: theme.body,
        position: "absolute",
        bottom: theme.key === "valorant" ? "2rem" : "1.3rem",
        transform: "translateY(-50%)",
        left: "2rem",
        right: "2rem",
      }}
    >
      <div css={{ paddingRight: "2em" }}>eDPI: {eDpi}</div>
      {theme.key === "valorant" && (
        <div css={{ paddingRight: "2em" }}>vDPI: {vDpi}*</div>
      )}
      <div>{cm360}cm / 360°</div>
    </div>
  );
}
